import Lenis from '@studio-freight/lenis';

const lenis = new Lenis({
  smoothWheel: true,
  wheelMultiplier: 0.4,
});

const raf = (time) => {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
