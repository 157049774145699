class AwardList {
  constructor(elem) {
    this.elem = elem;
    this.isOpen = false;
    this.button = this.elem.querySelector('[data-award-list-button]');
    this.init();
  }

  init = () => {
    this.button.addEventListener('click', this.toggle);
  }

  toggle = () => {
    this.isOpen = !this.isOpen;
    this.elem.classList.toggle('is-expanded', this.isOpen);
    this.button.title = this.isOpen ? 'Fold' : 'Expand';
    this.button.blur();
  }
}

document.querySelectorAll('[data-award-list]')
  .forEach((elem) => new AwardList(elem));