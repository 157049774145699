export default class Project {
  constructor(elem, updateParent) {
    this.elem = elem;
    this.updateParent = updateParent;
    this.bubble = this.elem.querySelector('[data-project-bubble]');
    this.closeButton = this.elem.querySelector('[data-project-close]');
    this.dialog = this.elem.querySelector('[data-project-dialog]');
    this.scrollContainer = this.elem.querySelector('[data-project-scroll-container]');
    this.name = this.dialog.dataset.projectDialog;

    // TODO: do better than setTimeout
    setTimeout(this.init, 150);
  }

  init = () => {
    const computedStyle = getComputedStyle(this.elem);
    this.scaleDuration = parseInt(computedStyle.getPropertyValue('--portfolio-bubble-duration'), 10);

    this.dialog.addEventListener('cancel', this.onCancel);
    this.bubble.addEventListener('click', this.open);
    this.closeButton.addEventListener('click', this.close);
  }

  open = () => {
    document.body.classList.add('is-modal-open');
    this.bubble.classList.add('is-selected');
    this.updateParent(true, this.elem);
    window.addEventListener('wheel', this.blockScroll, { passive: false });

    setTimeout(() => {
      this.dialog.showModal();
      const bubbleRect = this.bubble.getBoundingClientRect();
      const bubbleSize = bubbleRect.width;
      this.bubbleTop = bubbleRect.top;

      this.dialog.style.left = `${bubbleRect.left}px`;
      this.dialog.style.top = `${this.bubbleTop}px`;
      this.dialog.style.width = `${bubbleSize}px`;
      this.dialog.style.height = `${bubbleSize}px`;
      this.dialog.style.borderRadius = `${bubbleSize / 2}px`;

      requestAnimationFrame(() => {
        this.bubble.classList.remove('is-selected');
        this.bubble.classList.add('is-hidden');
        this.dialog.classList.add('is-entering');

        this.dialog.style.left = '';
        this.dialog.style.top = '';
        this.dialog.style.width = '';
        this.dialog.style.height = '';
        this.dialog.style.borderRadius = '';
      });
    }, this.scaleDuration);

    gtag('event', 'case_study', {
      'case_study_name': this.name,
    });
  }

  onCancel = (event) => {
    event.preventDefault();
    this.close();
  }

  // TODO: make the open and close animation more smooth
  // for example: use translate instead of left/right
  close = () => {
    const computedStyle = getComputedStyle(this.elem);
    const bubbleSize = parseInt(computedStyle.getPropertyValue('--portfolio-bubble-size'), 10);

    document.body.classList.remove('is-modal-open');
    this.dialog.classList.remove('is-entering');
    this.dialog.classList.add('is-exiting');
    this.dialog.style.left = `${window.innerWidth}px`;
    this.dialog.style.top = `${this.bubbleTop}px`;
    this.dialog.style.width = `${bubbleSize}px`;
    this.dialog.style.height = `${bubbleSize}px`;
    this.dialog.style.borderRadius = `${bubbleSize / 2}px`;
    this.updateParent(false, this.elem);

    setTimeout(() => {
      this.scrollContainer.scrollTop = 0;
      this.dialog.close();
      window.removeEventListener('wheel', this.blockScroll);

      this.bubble.classList.remove('is-hidden');
      this.bubble.classList.remove('is-selected');
      this.dialog.classList.remove('is-entering');
      this.dialog.classList.remove('is-exiting');

      this.dialog.style.left = '';
      this.dialog.style.top = '';
      this.dialog.style.width = '';
      this.dialog.style.height = '';
      this.dialog.style.borderRadius = '';

      // TODO: need to make sure that this duration is accurate
    }, 1000);
  }

  blockScroll = (event) => {
    if (!this.scrollContainer.contains(event.target)) {
      event.preventDefault();
    }
  }
}
