import { SCROLL_THRESHOLD } from './utils/consts';

class SectionObserver {
  constructor() {
    this.observer = new IntersectionObserver(this.handler, {
      rootMargin: `0% 0% -${(1 - SCROLL_THRESHOLD) * 100}% 0%`,
    });
    this.resetObserver = new IntersectionObserver(this.resetHandler, {
      rootMargin: '0% 0% 0% 0%',
    });
    this.triggers = [...document.querySelectorAll('[data-scroll-trigger]')];
    this.init();
  }

  init = () => {
    this.triggers.forEach((section) => {
      this.observer.observe(section);
      this.resetObserver.observe(section);
    });
  }

  handler = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-activated');
        entry.target.classList.remove('is-past');
        const event = new Event('activate');
        entry.target.dispatchEvent(event);
      } else {
        const isPast = entry.boundingClientRect.top < 0;
        entry.target.classList.toggle('is-past', isPast);
      }
    });
  }

  resetHandler = (entries) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        const isPast = entry.boundingClientRect.top < 0;
        if (!isPast) {
          entry.target.classList.remove('is-activated');
          const event = new Event('reset');
          entry.target.dispatchEvent(event);
        }
      }
    });
  }
}

new SectionObserver();
