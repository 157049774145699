class Heading {
  constructor(elem) {
    this.elem = elem;
    this.en = this.elem.querySelector('[data-heading-en]');
    this.cn = this.elem.querySelector('[data-heading-cn]');
    this.started = false;
    this.timeouts = [];

    this.init();
  }

  init = () => {
    this.cn.innerHTML = this.splitChars(this.cn);
    const enInner = this.splitChars(this.en);
    this.en.innerHTML =
      `<span class="heading__en__top"><span class="heading__en__inner">${enInner}</span></span>
      <span class="heading__en__bottom"><span class="heading__en__inner">${enInner}</span></span>`

    this.elem.addEventListener('activate', this.activateHandler);
    this.elem.addEventListener('reset', this.resetHandler);
  }

  activateHandler = () => {
    if (!this.started) {
      this.startTimeline();
      this.started = true;
    }
  }

  resetHandler = () => {
    this.started = false;
    this.elem.classList.remove('is-enabled');
    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.elem.dataset.headingStep = '';
  }

  splitChars = (stringElem) => {
    const textList = stringElem.textContent === 'Portfolio' ?
      ['P', 'o', 'r', 'tf', 'o', 'l', 'i', 'o'] : [...stringElem.textContent]
    return textList
      .map((char, index) =>
        `<span class="heading__char heading__char--${char}" style="--char-index: ${index};">${char}</span>`
      )
      .join('');
  }

  startTimeline = () => {
    this.elem.classList.add('is-enabled');
    this.timeouts.push(setTimeout(() => this.elem.dataset.headingStep = 0, 500));
    this.timeouts.push(setTimeout(() => this.elem.dataset.headingStep = 1, 1300));
    this.timeouts.push(setTimeout(() => this.elem.dataset.headingStep = 2, 3300));
  }
}

document.querySelectorAll('[data-heading]').forEach(elem => new Heading(elem));
