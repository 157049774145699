// black: #280000
// purple: #7e6bF4
// tangerine: #ff9a8c

const regularStyle = 'background: #7e6bF4; color: #280000; font-size: 12px;';
const highlightStyle = 'background: #7e6bF4; color: #ff9a8c; font-size: 12px;';

console.log(`
%cHi 👋 this is %cZhihao

%cThis site is made with 11ty, SCSS, and vanilla JavaScript. Matter.js is used as the physics engine, and Lenis is used to normalize scrolling across browsers.

%cSpecial thanks to %cJason Dietrick %cand %cMarcio Gutheil %cfor their design help.

https://www.jasondietrick.com/
https://marciogutheil.com/
`,
  regularStyle, highlightStyle,
  regularStyle,
  regularStyle, highlightStyle, regularStyle, highlightStyle, regularStyle,
);