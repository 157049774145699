class Beliefs {
  constructor(elem) {
    this.elem = elem;
    this.quotes = [...this.elem.querySelectorAll('[data-beliefs-quote]')];

    this.init();
  }

  init = () => {
    this.elem.addEventListener('reset', this.reset);
    this.elem.addEventListener('activate', this.activate);
  }

  reset = () => {
    this.quotes.forEach((quote) => {
      quote.removeEventListener('mouseenter', this.hoverHandler);
      quote.removeEventListener('touchstart', this.hoverHandler);
      quote.classList.remove('is-front');
    })
  }

  activate = () => {
    this.quotes.forEach((quote) => {
      quote.addEventListener('mouseenter', this.hoverHandler);
      quote.addEventListener('touchstart', this.hoverHandler);
    })
  }

  hoverHandler = (event) => {
    const index = this.quotes.indexOf(event.currentTarget);
    if (index > -1) {
      this.quotes.splice(index, 1);
      this.quotes.unshift(event.currentTarget);
    }
    this.updateOrders();
  }

  updateOrders = () => {
    this.quotes.forEach((quote, index) => {
      quote.style.setProperty('--beliefs-distance', index);
      quote.classList.toggle('is-front', index === 0);
    })
  }
}

document.querySelectorAll('[data-beliefs]').forEach(elem => new Beliefs(elem));
