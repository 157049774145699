import '../../includes/elements/border/border';
import '../../includes/elements/heading/heading';

import '../../includes/color/color';
import '../../includes/hero/hero';
import '../../includes/beliefs/beliefs';
import '../../includes/portfolio/portfolio';
import '../../includes/experience-awards/experience-awards';
import '../../includes/footer/footer';

import './sectionObserver';
import './smoothScroll';
import './consoleText';

history.scrollRestoration = 'manual';
window.addEventListener('beforeunload', () => {
  window.scrollTo(0, 0);
});