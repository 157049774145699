import BallPool from './ball-pool';
import CaseStudies from './case-studies';
import throttle from '../../assets/scripts/utils/throttle';
import { SCROLL_THRESHOLD } from '../../assets/scripts/utils/consts';

class Portfolio {
  constructor(elem) {
    this.elem = elem;
    this.container = this.elem.querySelector('[data-portfolio-container]');
    this.titles = [...this.elem.querySelectorAll('[data-portfolio-title]')];
    this.colorTrigger = this.elem.querySelector('[data-color]');

    setTimeout(this.init, 150);
  }

  init = () => {
    this.currentSlide = 0;
    this.ballPool = new BallPool(this.elem.querySelector('[data-ballpool]'));
    this.caseStudies = new CaseStudies(this.elem.querySelector('[data-casestudies]'));
    this.ballPool.setSisterScene(this.caseStudies);
    this.caseStudies.setSisterScene(this.ballPool);
    this.slides = [this.ballPool, this.caseStudies];
    window.addEventListener('scroll', throttle(this.scrollHandler, 300));
    this.container.addEventListener('activate', this.activateHandler);
    this.container.addEventListener('reset', this.resetHandler);
  }

  scrollHandler = () => {
    const rect = this.colorTrigger.getBoundingClientRect();
    const currentSlide = rect.top > window.innerHeight * SCROLL_THRESHOLD ? 0 : 1;
    if (currentSlide !== this.currentSlide) {
      this.currentSlide = currentSlide;
      this.slides[this.currentSlide].transitionIn();
      this.slides[1 - this.currentSlide].cancelActivate();
      this.titles.forEach((title, index) => {
        title.classList.toggle('is-activated', index === this.currentSlide);
      });
    }
  }

  activateHandler = () => {
    this.slides[this.currentSlide].activate();
    this.slides[1 - this.currentSlide].cancelActivate();
    this.titles[this.currentSlide].classList.add('is-activated');
  }

  resetHandler = () => {
    this.slides.forEach(slide => slide.reset());
    this.titles.forEach(title => title.classList.remove('is-activated'));
  }
}

document.querySelectorAll('[data-portfolio]').forEach((elem) => new Portfolio(elem));
