import Project from './project';

const MOVE_DURATION = 2000;
const MOVE_ACCELERATION = 1 / 90;

export default class CaseStudies {
  constructor(elem) {
    this.elem = elem;
    this.projects = [...this.elem.querySelectorAll('[data-casestudies-project]')];
    this.speed = 0;
    this.targetSpeed = 0;
    this.isActivated = false;

    this.init();
  }

  init = () => {
    this.elem.querySelectorAll('[data-project]')
      .forEach((project) => new Project(project, this.projectEvent));

    window.addEventListener('resize', this.resetAnimation);
    this.resetAnimation();
  }

  resetAnimation = () => {
    const computedStyle = getComputedStyle(this.elem);
    this.scaleDuration = parseInt(computedStyle.getPropertyValue('--portfolio-bubble-duration'), 10);
    this.visiblePerRow = parseFloat(computedStyle.getPropertyValue('--portfolio-bubble-per-row'), 10);
    this.widthWidthGap = window.innerWidth / this.visiblePerRow;
    this.bubbleSize = this.widthWidthGap * .9;
    this.gap = this.widthWidthGap - this.bubbleSize;
    this.elem.style.setProperty('--portfolio-bubble-size', `${this.bubbleSize}px`);
    this.array = [];
    this.projects.forEach((project) => {
      this.array.push(project);
    })

    this.updateBubblePositions();

    document.addEventListener('visibilitychange', this.onVisibilityChange)

    this.lastTime = null;
    this.currentTime = 0;
    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.onFrame);
  }

  onVisibilityChange = () => {
    this.lastTime = null;
  }

  onFrame = (time) => {
    const delta = this.lastTime === null ? 0 : time - this.lastTime;
    this.lastTime = time;

    if (this.speed > this.targetSpeed) {
      this.speed = Math.max(0, this.speed - MOVE_ACCELERATION);
    } else if (this.speed < this.targetSpeed) {
      this.speed = Math.min(1, this.speed + MOVE_ACCELERATION);
    }

    if (this.speed > 0) {
      let currentTime = this.currentTime + delta * this.speed;
      let skip = false;

      if (currentTime >= MOVE_DURATION &&
        !this.iterationEnded) {
        if (this.targetSpeed === 0) {
          skip = true;
        } else {
          currentTime = currentTime % MOVE_DURATION;

          this.endIteration();
          this.iterationStarted = false;
          this.iterationEnded = true;
        }
      } else if (!this.iterationStarted) {
        this.iterationStarted = true;
        this.iterationEnded = false;
      }

      if (skip) {
        this.speed = 0;
      } else {
        this.currentTime = currentTime;
      }

      this.updateMove();
    }

    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.onFrame);
  }

  endIteration = () => {
    this.array.push(this.array.shift());
    this.updateBubblePositions();
  }

  updateMove = () => {
    this.elem.style.translate = `-${this.currentTime / MOVE_DURATION * this.widthWidthGap}px 0 0`;
  }

  updateBubblePositions = () => {
    this.array.forEach((project, colIndex) => {
      project.style.top = top;
      project.style.left = `${colIndex * this.widthWidthGap}px`;
    });
  }

  projectEvent = (open, target) => {
    if (open) {
      this.pause();

      let targetCol;
      let delay = 200;
      let targetFound = false;
      let lastPosition;
      this.array.forEach((project, colIndex) => {
        if (project === target) {
          targetFound = true;
          lastPosition = project.style.left;
          targetCol = colIndex;
        } else if (targetFound) {
          // this project is on the same row as the
          // target, and is after the target
          project.classList.add('is-moving');
          const position = project.style.left;
          project.style.left = lastPosition;
          project.style.transitionDelay = `${delay}ms`;
          delay += 60;
          lastPosition = position;
        }
      })
      this.array.splice(targetCol, 1);
      this.array.push(target);
    } else {
      this.array.forEach((project) => {
        project.classList.remove('is-moving');
        project.style.transitionDelay = '';
      })
      this.updateBubblePositions();

      this.resume();
    }
  }

  pause = () => {
    this.targetSpeed = 0;
  }

  resume = () => {
    this.targetSpeed = 1;
  }

  // public functions

  setSisterScene = (scene) => {
    this.sisterScene = scene;
  }

  transitionOut = () => {
    this.elem.classList.add('is-exiting');
    this.exitTimeout = setTimeout(this.reset, 400);
  }

  transitionIn = () => {
    const destinationsForBallPool = this.array.map((project) => {
      const rect = project.getBoundingClientRect();
      return {
        name: project.dataset.casestudiesProject,
        left: rect.left,
        top: rect.top,
        width: rect.width,
      };
    });
    this.sisterScene.transitionOut(destinationsForBallPool);
    clearTimeout(this.activateTimeout);
    this.activateTimeout = setTimeout(() => this.activate(), 1900);
  }

  activate = () => {
    if (this.isActivated) {
      return
    }
    clearTimeout(this.exitTimeout);
    this.elem.classList.remove('is-exiting');
    this.elem.classList.add('is-activated');
    this.resume();
    this.isActivated = true;
  }

  cancelActivate = () => {
    clearTimeout(this.activateTimeout);
  }

  reset = () => {
    if (!this.isActivated) {
      return;
    }
    clearTimeout(this.activateTimeout);
    clearTimeout(this.exitTimeout);
    this.elem.classList.remove('is-activated');
    this.elem.classList.remove('is-exiting');
    this.pause();
    this.isActivated = false;
  }
}
