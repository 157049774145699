import Matter from 'matter-js';

const CANVAS_WIDTH = 800;
const {
  Engine,
  Bodies,
  Body,
  Composite,
} = Matter;

export default class BallPool {
  constructor(elem, count = 30) {
    this.elem = elem;
    this.ballElems = [...this.elem.querySelectorAll('[data-ballpool-ball]')];
    this.placeholder = this.elem.querySelector('[data-ballpool-placeholder]');
    this.insertBallTimeouts = [];
    this.balls = [];
    this.engine = Engine.create({
      gravity: {
        y: 0.4,
      },
    });

    this.init();
  }

  init = () => {
    const computedStyle = getComputedStyle(this.elem);
    this.CANVAS_HEIGHT = CANVAS_WIDTH / parseFloat(computedStyle.getPropertyValue('--aspect-ratio'), 10);
    this.ballSize = parseFloat(computedStyle.getPropertyValue('--ball-size'), 10) * CANVAS_WIDTH / 2;
    this.lastSize = this.ballSize;

    this.ballElems.forEach(() => {
      const ball = Bodies.circle(CANVAS_WIDTH / 2, this.CANVAS_HEIGHT - this.ballSize / 2, this.ballSize, { restitution: 0.5 });

      this.balls.push(ball);
    });

    Composite.add(this.engine.world, [
      Bodies.rectangle(CANVAS_WIDTH / 2, -20, CANVAS_WIDTH, 40, { isStatic: true, restitution: 0.5 }), // top wall
      Bodies.rectangle(CANVAS_WIDTH + 20, this.CANVAS_HEIGHT / 2, 40, this.CANVAS_HEIGHT, { isStatic: true, restitution: 0.5 }), // right wall
      Bodies.rectangle(CANVAS_WIDTH / 2, this.CANVAS_HEIGHT + 20, CANVAS_WIDTH, 40, { isStatic: true, restitution: 0.5 }), // bottom wall
      Bodies.rectangle(-20, this.CANVAS_HEIGHT / 2, 40, this.CANVAS_HEIGHT, { isStatic: true, restitution: 0.5 }), // left wall
    ]);

    window.addEventListener('resize', this.resizeHandler);
    setTimeout(this.resizeHandler, 300);
  }

  resizeHandler = () => {
    const computedStyle = getComputedStyle(this.elem);
    const ballSize = parseFloat(computedStyle.getPropertyValue('--ball-size'), 10) * CANVAS_WIDTH / 2;
    const scale = Math.sqrt(ballSize / this.lastSize);
    this.lastSize = this.ballSize;
    this.ballSize = ballSize;
    this.balls.forEach(ball => Body.scale(ball, scale, scale))
  }

  onVisibilityChange = () => {
    this.lastTime = null;
  }

  update = (time) => {
    const delta = this.lastTime === null ? 0 : time - this.lastTime;
    this.lastTime = time;

    Engine.update(this.engine, delta);
    this.balls.forEach((ball, index) => {
      this.ballElems[index].style.translate = `${this.getTranslate(ball.position.x)}px ${this.getTranslate(ball.position.y)}px 0`;
      this.ballElems[index].style.rotate = `${Math.min(Math.max(ball.angle, -1.2), 1.2) * 0.5}rad`;
    })

    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.update)
  }

  getTranslate = (position) => {
    return position / CANVAS_WIDTH * window.innerWidth;
  }

  // public functions

  setSisterScene = (scene) => {
    this.sisterScene = scene;
  }

  transitionOut = (destinations) => {
    if (destinations.length === 0) {
      return;
    }
    cancelAnimationFrame(this.raf);

    clearTimeout(this.transitionOutTimeout);
    const orig = this.placeholder.getBoundingClientRect();
    const scale = destinations[0].width / orig.width;

    destinations.forEach((dest, index) => {
      const ball = this.ballElems.find(elem => elem.dataset.ballpoolBall === dest.name);
      const newTranslateX = dest.left - orig.left + dest.width * 0.5 - orig.width * 0.5;
      const newTranslateY = dest.top - orig.top + dest.width * 0.5 - orig.width * 0.5;

      ball.classList.add('is-moving-out');
      ball.style.scale = scale;
      ball.style.translate = `${newTranslateX}px ${newTranslateY}px 0`;
      ball.style.rotate = '0rad';
      ball.style.setProperty('--project-index', index);
    });
    this.elem.classList.add('is-exiting');
    this.transitionOutTimeout = setTimeout(() => {
      this.elem.classList.remove('is-activated');
    }, 2000);
  }

  transitionIn = () => {
    this.sisterScene.transitionOut();
    this.reset();
    this.activate();
  }

  cancelActivate = () => {
    return;
  }

  activate = () => {
    this.balls.forEach((ball, index) => {
      this.insertBallTimeouts.push(setTimeout(() => {
        Body.setPosition(ball, {
          x: CANVAS_WIDTH / 2,
          y: this.CANVAS_HEIGHT - this.ballSize / 2,
        })
        Body.setVelocity(ball, {
          x: (Math.random() - 0.5) * 8,
          y: -2,
        })
        Body.setSpeed(ball, 5);
        Composite.add(this.engine.world, ball);
        this.ballElems[index].classList.add('is-activated');
      }, 50 * index));
    });

    document.addEventListener('visibilitychange', this.onVisibilityChange)

    this.lastTime = null;
    this.currentTime = 0;
    cancelAnimationFrame(this.raf);
    this.raf = requestAnimationFrame(this.update);
    this.elem.classList.add('is-activated');
    this.elem.classList.remove('is-exiting');
  }

  reset = () => {
    this.insertBallTimeouts.forEach(timeout => clearTimeout(timeout));
    clearTimeout(this.transitionOutTimeout);
    this.insertBallTimeouts = [];
    this.balls.forEach((ball) => {
      Composite.remove(this.engine.world, ball);
    });
    this.ballElems.forEach((ballElem) => {
      ballElem.classList.remove('is-activated');
      ballElem.classList.remove('is-moving-out');
      ballElem.style.scale = '';
    });
    this.elem.classList.remove('is-exiting');
    this.elem.classList.remove('is-activated');
    document.removeEventListener('visibilitychange', this.onVisibilityChange);
    cancelAnimationFrame(this.raf);
  }
}
