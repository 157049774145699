class Border {
  constructor(elem) {
    this.elem = elem;
    this.rect = this.elem.querySelector('[data-border-rect]');

    this.init();
  }

  init = () => {
    this.ry = parseFloat(this.rect.getAttribute('ry'));

    window.addEventListener('resize', this.resizeHandler);
    document.fonts.ready.then(this.resizeHandler);
    this.resizeHandler();
  }

  resizeHandler = () => {
    this.rect.setAttribute('ry', Math.min(this.ry, this.elem.clientHeight / 2))
  }
}

document.querySelectorAll('[data-border][data-border-auto-radius]').forEach(elem => new Border(elem));
