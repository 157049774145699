import throttle from '../../assets/scripts/utils/throttle';

class ColorObserver {
  constructor () {
    this.colorLines = [...document.querySelectorAll('[data-color]')];
    this.init();
  }

  init = () => {
    this.colors = {
      purple: {
        front: 'black',
        back: 'purple',
        highlight: 'tangerine',
      },
      black: {
        front: 'purple',
        back: 'black',
        highlight: 'purple-light',
      },
      tangerine: {
        front: 'black',
        back: 'tangerine-light',
        highlight: 'purple-light',
      },
    }
    window.addEventListener('scroll', throttle(this.scrollHandler, 300));
  }

  scrollHandler = () => {
    const threashold = window.innerHeight * 0.7 + window.scrollY;
    let currentIndex = 0;
    for (let index = 0; index < this.colorLines.length; index++) {
      let top = this.colorLines[index].offsetTop;
      if (this.colorLines[index].offsetParent !== document.body) {
        const rect = this.colorLines[index].getBoundingClientRect();
        top = rect.top + window.scrollY;
      }
      if (top < threashold) {
        currentIndex = index;
      } else {
        break;
      }
    }

    this.setColors(this.colorLines[currentIndex].dataset.color);
  }

  setColors = (color) => {
    const newColors = this.colors[color];
    document.documentElement.style.setProperty('--color-front', `var(--color-${newColors.front})`);
    document.documentElement.style.setProperty('--color-back', `var(--color-${newColors.back})`);
    document.documentElement.style.setProperty('--color-highlight', `var(--color-${newColors.highlight})`);
  }
}

new ColorObserver();
